import { FilterOption } from "../../component/material-table/material-table.component";
import { AxonUtils } from "../../utils/axon-utils";
import { Customer, Submission } from "../../dto/dtos";

export interface SubmissionHistoryTableColumn {
	columnDef: string;
	header: string;
	cell: (element: any) => any;
	isNoWrap?: boolean;
	isTxtDownload?: boolean;
	isDateTime?: boolean;
}
export const columns: SubmissionHistoryTableColumn[] = [
	{ columnDef: 'id'          , header: 'ID'        , cell: (element: Submission) => `${element.id}` },
	{ columnDef: 'axonId'      , header: 'Axon ID'   , cell: (element: Submission) => `${element.axonId}` },
	{ columnDef: 'pinref'      , header: 'Pinref'    , cell: (element: Submission) => `${element.pinref}` },
	{ columnDef: 'msisdn'      , header: 'MSISDN'    , cell: (element: Submission) => `${element.msisdn}` },
	{ columnDef: 'iccid'       , header: 'ICCID'     , cell: (element: Submission) => `${element.iccid}` },
	{ columnDef: 'type'        , header: 'Type'      , cell: (element: Submission) => `${element.type}` },
	{ columnDef: 'queueDate'   , header: 'Queue Date', cell: (element: Submission) => `${element.queueDate}`, isDateTime: true },
	{ columnDef: 'startDate'   , header: 'Start Date', cell: (element: Submission) => `${element.startDate}`, isDateTime: true },
	{ columnDef: 'endDate'     , header: 'End Date'  , cell: (element: Submission) => `${element.endDate}`, isDateTime: true },
	{ columnDef: 'timing'      , header: 'Timing'    , cell: (element: Submission) => `${element.timing}` },
	{ columnDef: 'attempt'     , header: 'Attempt'   , cell: (element: Submission) => `${element.attempt}` },
	{ columnDef: 'status'      , header: 'Status'    , cell: (element: Submission) => `${element.status}` },
	{ columnDef: 'tpRef'       , header: 'TP Ref'    , cell: (element: Submission) => `${element.tpRef}` },
	{ columnDef: 'tpResultCode', header: 'TP Code'   , cell: (element: Submission) => `${element.tpCode}` },
	{ columnDef: 'tpResultMsg' , header: 'TP MSG'    , cell: (element: Submission) => `${element.tpMsg}`, isNoWrap: true },
	{ columnDef: 'tpRequest'   , header: 'Request'   , cell: (element: Submission) => `${element.tpRequest}`, isTxtDownload: true },
	{ columnDef: 'tpResponse'  , header: 'Response'  , cell: (element: Submission) => `${element.tpResponse}`, isTxtDownload: true },
	{ columnDef: 'error'       , header: 'Error'     , cell: (element: Submission) => `${element.error}`, isNoWrap: true },
];
export const columnsWithoutTpRequest: SubmissionHistoryTableColumn[] = [
	{ columnDef: 'id'          , header: 'ID'        , cell: (element: Submission) => `${element.id}` },
	{ columnDef: 'axonId'      , header: 'Axon ID'   , cell: (element: Submission) => `${element.axonId}` },
	{ columnDef: 'pinref'      , header: 'Pinref'    , cell: (element: Submission) => `${element.pinref}` },
	{ columnDef: 'msisdn'      , header: 'MSISDN'    , cell: (element: Submission) => `${element.msisdn}` },
	{ columnDef: 'iccid'       , header: 'ICCID'     , cell: (element: Submission) => `${element.iccid}` },
	{ columnDef: 'type'        , header: 'Type'      , cell: (element: Submission) => `${element.type}` },
	{ columnDef: 'queueDate'   , header: 'Queue Date', cell: (element: Submission) => `${element.queueDate}`, isDateTime: true },
	{ columnDef: 'startDate'   , header: 'Start Date', cell: (element: Submission) => `${element.startDate}`, isDateTime: true },
	{ columnDef: 'endDate'     , header: 'End Date'  , cell: (element: Submission) => `${element.endDate}`, isDateTime: true },
	{ columnDef: 'timing'      , header: 'Timing'    , cell: (element: Submission) => `${element.timing}` },
	{ columnDef: 'attempt'     , header: 'Attempt'   , cell: (element: Submission) => `${element.attempt}` },
	{ columnDef: 'status'      , header: 'Status'    , cell: (element: Submission) => `${element.status}` },
	{ columnDef: 'tpRef'       , header: 'TP Ref'    , cell: (element: Submission) => `${element.tpRef}` },
	{ columnDef: 'tpResultCode', header: 'TP Code'   , cell: (element: Submission) => `${element.tpCode}` },
	{ columnDef: 'tpResultMsg' , header: 'TP MSG'    , cell: (element: Submission) => `${element.tpMsg}`, isNoWrap: true },
	{ columnDef: 'tpResponse'  , header: 'Response'  , cell: (element: Submission) => `${element.tpResponse}`, isTxtDownload: true },
	{ columnDef: 'error'       , header: 'Error'     , cell: (element: Submission) => `${element.error}`, isNoWrap: true },
];

export const filters: FilterOption[] = [
	{ order: 0, filter: 'MSISDN'  , type: 'text' },
	{ order: 1, filter: 'Type'   , type: 'text' },
];



