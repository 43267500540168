
import { Observable } from 'rxjs';
import { DataResponse } from '../../../../dto/dtos';
import { TableData } from '../table-data';
import { Injectable } from '@angular/core';
import { FilterOption } from '../../material-table.component';
import { ApprovalService } from '../../../../services/approvals/approval.service';

@Injectable({
    providedIn: 'root'
})
/**
 * Overrides getTableData by fetching records for approval review
 */
export class ApprovalReviewTableData extends TableData {
    constructor(private approvalService: ApprovalService) {
        super(approvalService);
    }

    getTableData(sort: string, order: string, page: number, pageSize: number, filterOptions: FilterOption[]): Observable<DataResponse> {
        return this.approvalService.getApprovalReview(filterOptions);
    }
}
