import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Data } from '@angular/router';
import { AuthService } from "./auth.service";
import { NO_PERMISSION_FOR_ACTION, Permission as PermissionEnum } from "../../utils/constants";
import { Notifier } from '../../utils/notifier';
import { AxonUtils } from '../../utils/axon-utils';

@Injectable({
	providedIn: 'root'
})
export class HasPermissionGuard implements CanActivate {

	constructor(private authService: AuthService, private notifier: Notifier, private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (route.firstChild) {
			return this.canActivateRoute(route.firstChild.data);
		}
		return this.canActivateRoute(route.data);
	}

	private canActivateRoute(data: Data): boolean {
		const permissions: PermissionEnum[] = data['permissions'];
		for (const permission of permissions) {
			if (AxonUtils.checkActionPermission(permission, this.authService, this.notifier, true)) {
				return true;
			}
		}
		return false;
	}
}
