import { FilterOption } from "../../component/material-table/material-table.component";

export interface AgentTableColumn {
	columnDef: string;
	header: string;
	cell: (element: any) => string;
	isCheckbox?: boolean;
	isImage?: boolean;
	isDateTime?: boolean;
	isAccountStatus?: boolean;
	isApprovalStatus?: boolean;
	isAgentLink?: boolean;
	agentLinkId?: (element: any) => string;
	isButtons?: boolean;
}
export const columns: AgentTableColumn[] = [
	{
		columnDef: 'checkbox',
		header: '',
		cell: (element: any) => `${element.selected}`,
		isCheckbox: true
	},
	{
		columnDef: 'agentSelfie',
		header: 'Agent',
		cell: (element: any) => `${element.imageUrl}`,
		isImage: true
	},
	{
		columnDef: 'agent_id',
		header: 'Agent ID',
		cell: (element: any) => `${element.agentId}`,
	},
	{
		columnDef: 'name',
		header: 'First Name',
		cell: (element: any) => `${element.name}`,
	},
	{
		columnDef: 'othernames',
		header: 'Middle Name',
		cell: (element: any) => `${element.othernames}`,
	},
	{
		columnDef: 'surname',
		header: 'Last Name',
		cell: (element: any) => `${element.surname}`,
	},
	{
		columnDef: 'msisdn',
		header: 'MSISDN',
		cell: (element: any) => `${element.msisdn}`,
	},
	{
		columnDef: 'username',
		header: 'Username',
		cell: (element: any) => `${element.username}`,
	},
	{
		columnDef: 'lastaccessed',
		header: 'Last Accessed',
		cell: (element: any) => `${element.lastAccessed}`,
		isDateTime: true
	},
	{
		columnDef: 'accountStatus',
		header: 'Disabled/Enabled',
		cell: (element: any) => `${element.accountStatus}`,
		isAccountStatus: true
	},
	{
		columnDef: 'status',
		header: 'Blocked/Unblocked',
		cell: (element: any) => `${element.status}`,
		isApprovalStatus: true
	},
	{
		columnDef: 'statusReason',
		header: 'Status Reason',
		cell: (element: any) => `${element.statusReason}`
	},
	{
		columnDef: 'pos',
		header: 'POS',
		cell: (element: any) => `${element.supervisorFullname}`,
		isAgentLink: false,
		agentLinkId: (element: any) => `${element.supervisorAgentId}`
	},
	{
		columnDef: 'buttons',
		header: '',
		cell: (element: any) => `${element.username}`,
		isButtons: true
	}
];
